<template>
  <div :id="id" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" footer-class="footerClass" title="Edit Assigned Staff"
      no-close-on-backdrop  content-class="shadow"
      @hidden="modalCancel"
      scrollable
    >
      <b-form-group>
        <label class="mr-1">{{ $t(`task.field.staffs`) }}</label>
        <button class="btn-action" @click="staffSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/></button>
      
        <BadgeGroup v-model="staffs">
          <template v-slot:default="{ item, index }">
            <Badge @badgeRemove="staffBadgeRemove(index)" @badgeClick="staffBadgeClick(item.uuId)"
              :text="hideUtilization ? item.name : `${item.name} (${item.unit > 1? item.unit + ' x ': ''}${Math.trunc(item.utilization * 100)}%)`" 
              :variant="item.genericStaff? 'info' : 'primary'"
              :pillable="!!item.pillable" :key="index" />
            </template>
        </BadgeGroup>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <!-- staff selector -->
    <StaffSelectorModalForAdmin v-if="staffSelectorShow"
      :show.sync="staffSelectorShow" 
      hideBookings
      :companies="companies"
      :taskUuId="taskUuId"
      nonAdmin
      @ok="staffSelectorOk"
      @cancel="staffSelectorCancel" 
    />
    <StaffUtilizationModal v-if="staffUtilizationEditShow" :show.sync="staffUtilizationEditShow" 
      :uuId="staffUtilizationEdit.uuId" 
      :name="staffUtilizationEdit.name" 
      :utilization="staffUtilizationEdit.utilization" 
      :unit="staffUtilizationEdit.unit"
      :genericStaff="staffUtilizationEdit.genericStaff" 
      @ok="staffUtilizationOk"/>
    <template v-for="(item, index) in toConfirmStaffs">
      <StaffUtilizationModal :key="`staff-${index}`" :show.sync="item.show" 
        :canApplyAll="toConfirmStaffs.length > 1" 
        :uuId="item.uuId" 
        :name="item.name" 
        :utilization="item.utilization" 
        :unit="item.unit"
        :genericStaff="item.genericStaff" 
        @ok="toConfirmStaffOk" 
        @cancel="toConfirmStaffCancel"/>
    </template>
  </div>
</template>

<script>
import { strRandom } from '@/helpers';
import { cloneDeep } from 'lodash';

export default {
  name: 'StaffBadgeGroupModal',
  components: {
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    StaffSelectorModalForAdmin: () => import('@/components/modal/StaffSelectorModalForAdmin'),
    StaffUtilizationModal: () => import('@/components/modal/StaffUtilizationModal'),
  },
  props: {
    show: { type: Boolean, required: true },
    staffList: { type: Array, default: () => [] },
    taskUuId: { type: String, default: null },
    companies: { type: Array, default: () => [] },
    hideUtilization: { type: Boolean, default: false }
  },
  data() {
    return {
      modalShow: false,
      staffSelectorShow: false,
      staffUtilizationEditShow: false,
      staffs: [],
      toConfirmStaffs: [],
      staffUtilizationEdit: {
        uuId:  null,
        name:  null,
        utilization: null,
        unit: null,
        genericStaff: false
      },
    }
  },
  created() {
    this.id = `STAFF_BADGE_GROUP_MODAl_${strRandom(5)}`;
  },
  beforeMount() {
    this.staffs.splice(0, this.staffs.length, ...this.staffList);
    this.modalShow = true;
  },
  mounted() {

  },
  beforeDestroy() {

  },
  watch: {
    show(newValue) {
      if (newValue != this.modalShow) {
        this.modalShow = newValue;
        if (newValue) {
          this.staffs.splice(0, this.staffs.length, ...this.staffList);
        }
      }
    }
  },
  methods: {
    modalCancel() {
      this.$emit('cancel');
    },
    staffSelectorCancel() {
      this.staffSelectorShow = false;
    },
    // staffSelectorDeleted(payload) {
    //   this.$emit('staffDeleted', payload);
    // },
    staffBadgeRemove: function(index) {
      this.staffs.splice(index,1)
    },
    staffBadgeClick(id) {
      const selected = this.staffs.find(i => i.uuId === id);
      const edit = this.staffUtilizationEdit;
      edit.uuId = selected.uuId;
      edit.name = selected.name;
      edit.utilization = selected.utilization;
      edit.unit = selected.unit;
      edit.genericStaff = selected.genericStaff;
      
      
      if (this.hideUtilization) {
        this.staffSelectorToggle();
        return;
      }
      this.staffUtilizationEditShow = true;
    },
    staffSelectorToggle() {
      this.staffSelectorShow = true;
    },
    staffSelectorOk({ details }) {
      const newStaffs = details.map(i => { 
        return { 
          uuId: i.uuId
          , name: i.name
          , utilization: null
          , show: false
          , duration: i.duration
          , genericStaff: i.genericStaff 
        }
      });
      this.toConfirmStaffs.splice(0, this.toConfirmStaffs.length, ...newStaffs);
      for(let i = this.toConfirmStaffs.length-1; i > -1; i--) {
        if (this.hideUtilization) {
          this.toConfirmStaffs[i].toBeShown = false;
          this.toConfirmStaffs[i].utilization = 1;
        }
        else {
          this.toConfirmStaffs[i].toBeShown = true;
        }
      }
      
      if (this.hideUtilization) {
        if (this.staffUtilizationEdit.uuId) {
          const index = this.staffs.findIndex(s => s.uuId === this.staffUtilizationEdit.uuId);
          if (index !== -1) {
            this.staffs.splice(index, 1);
          }
        }
        this.toConfirmStaffCommit();
      }
      else {
        this.toConfirmStaffs[this.toConfirmStaffs.length - 1].toBeShown = false;
        this.toConfirmStaffs[this.toConfirmStaffs.length - 1].show = true;
      }
    },
    toConfirmStaffOk({ id, name, utilization, oldId, applyToAll, genericStaff }) {
      const oldIdIndex = oldId? this.toConfirmStaffs.findIndex(i => i.uuId === oldId) : -1;
      let selected = null;
      if(oldIdIndex != -1) {
        selected= this.toConfirmStaffs[oldIdIndex];
        selected.uuId = id;
        selected.name = name;
      } else {
        selected = this.toConfirmStaffs.find(i => i.uuId === id);
      }
      
      selected.utilization = utilization;
      if(genericStaff != undefined) {
        selected.genericStaff = genericStaff;
      }
      const toBeShown = this.toConfirmStaffs.filter(i => i.toBeShown);
      if(toBeShown.length === 0) {
        this.toConfirmStaffCommit();
      }
      else if (applyToAll) {
        for (const entry of toBeShown) {
          entry.toBeShown = false;
          entry.utilization = utilization;
        }
        this.toConfirmStaffCommit();
      }
      else {
        toBeShown[toBeShown.length - 1].toBeShown = false;
        toBeShown[toBeShown.length - 1].show = true;
      }
    },
    toConfirmStaffCancel() {
      this.toConfirmStaffCommit();
    },
    toConfirmStaffCommit() {
      //Set all show state to false. To close/hide remaining modals if any.
      for(let i = 0, len = this.toConfirmStaffs.length; i < len; i ++) {
        this.toConfirmStaffs[i].show = false;
      }
      const confirmedStaffs = this.toConfirmStaffs.filter(i => i.utilization != null);
      const ids = confirmedStaffs.map(i => i.uuId);
      const currentIds = this.staffs.map(i => i.uuId);
      const duplicatedIds = currentIds.filter(i => ids.includes(i));
      const newStaffs = confirmedStaffs.filter(i => !currentIds.includes(i.uuId));
      
      let staff = null;
      for(let i = 0, len = this.staffs.length; i < len; i++) {
        staff = this.staffs[i];
        if(duplicatedIds.includes(staff.uuId)) {
          const found = confirmedStaffs.find(i => i.uuId === staff.uuId);
          staff.utilization = found.utilization;
          staff.unit = found.unit;
          // staff.utilization = confirmedStaffs.find(i => i.uuId === staff.uuId).utilization;
        }
      }

      newStaffs.forEach(i => {
        this.staffs.push( {uuId: i.uuId, name: i.name, utilization: i.utilization, unit: i.unit, genericStaff: !!i.genericStaff} );
      });
    },
    staffUtilizationOk({ id, name, utilization, unit, oldId, genericStaff }) {
      const oldIdIndex = oldId? this.staffs.findIndex(i => i.uuId === oldId) : -1;
      let selected = null;
      if(oldIdIndex != -1) {
        selected= this.staffs[oldIdIndex];
      } else {
        selected = this.staffs.find(i => i.uuId === id);
      }
      if(selected != null) {
        selected.uuId = id;
        selected.name = name;
        selected.utilization = utilization;
        selected.unit = unit;
      } else {
        this.staffs.push({
          uuId: id,
          name: name,
          utilization: utilization,
          unit: unit,
          genericStaff: !!genericStaff
        });
      }
    },
    ok() {
      this.$emit('ok', cloneDeep(this.staffs));
      this.$emit('update:show', false);
    }
  }
}
</script>

<style>

</style>