<template>
  <div :id="`SANDBOX_FORM_${id}`" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="md" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow"
      @hidden="modalCancel"
      scrollable
    >
      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <b-alert variant="danger" dismissible v-model="errorShow" @dismissed="dismissAlert">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }} 
      </b-alert>

      <b-form-group :label="$t('sandbox.name')" label-for="name">
        <b-input-group>
          <b-form-select 
            v-model="sandbox" 
            :options="sandboxList">
          </b-form-select>
        </b-input-group>
      </b-form-group>
        
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { strRandom, EventBus } from '@/helpers';
import { sandboxService } from '@/services';
import { fieldValidateUtil } from '@/script/helper-field-validate';


export default {
  name: 'SandboxModal',
  components: {
  },
  props: {
    id:        { type: String,   default: `SANDBOX_NEW_${strRandom(5)}` },
    title:     { type: String,   default: null },
    readOnly:  { type: Boolean,  default: false },
    show:      { type: Boolean, required: true },
    visibility: { type: String, default: 'private' },
  },
  data() {
    return {
      permissionName: 'SANDBOX',
      modelInfo: null,
      alertMsg: null,
      state: {
        editable:            false,
        isSubmitting:        false,
        modalShow:           false
      },
      sandbox: null,
      sandboxList: []
    }
  },
  created() {
  },
  mounted() {
    this.state.modalShow = this.show;
  },
  beforeDestroy() {
  },
  computed: {
    isReadOnly() {
      return !this.state.editable && this.readOnly;
    },
    errorShow() {
      return this.alertMsg != null;
    },
    labelTitle() {
      return this.title? this.title: this.$t('sandbox.select');
    }
  },
  watch: {
    show(newValue) {
      if(newValue != this.state.modalShow) {
        this.state.modalShow = newValue;
        this.state.autoScheduleExpanded = false;
        this.alertMsg = null;
        sandboxService.list().then((response) => {
          this.sandboxList = response.data[response.data.jobCase].map(r => { return { value: r.identifier, text: r.name }});
          this.sandboxList.unshift({ value: null, text: this.$t('sandbox.none')});
        })
        .catch(e => {
          this.sandboxList.splice(0, this.sandboxList.length, { value: null, text: this.$t('sandbox.none')});
        });
      }
    }
  },
  methods: {
    keydownHandler(event) {
      if (event.which === 13) {
        // The key pressed was the enter key
        this.ok();
      }
    },
    ok() {
      this.errors.clear();
      this.alertMsg = null;
      this.sandboxSubmit();
    },
    async sandboxSubmit() {
      this.$store.state.sandbox.value = this.sandbox;
      this.$store.state.epoch.value = null; // clear the epoch if set
      let name = '';
      if (this.sandbox !== null) {
        const sandboxObj = this.sandboxList.find(s => s.value === this.sandbox);
        if (sandboxObj) {
          name = sandboxObj.text;
        }
      }
      else {
        name = this.$t('sidebar.epoch');
      }
      this.$store.state.sandbox.name = name;
      this.$emit('success', { sandbox: this.sandbox, name: name });
      EventBus.$emit('language-change');
      this.$emit('update:show', false)
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    modalCancel() {
      this.$emit('update:show', false)
    }
  }
}
</script>
